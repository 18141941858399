// LIGHT THEME
html {
  --bg: #faf7f2;
  --primary: #ccb279;
  --text: #000;
  --headerText: #5a5a5a;
  --headerColor: #786b99;
  --paginationColor: #333;
  --paginationActiveColor: #ecf2fa;
  --iconColor: #818e9b;
  --headerIconColor: #fff;
  --modalBackground: #fff;
  --modalText: #818e9b;
  --modalSubHeader: #000;
  --tableHeader: #fafafa;
  --tableHeaderColor: #818e9b;
  --checkboxColor: #3f7abf;
  --drawItemColor: #f2f2f2;
  --filtersHeader: #000;
  --tableRowGreen: #f2ffeb;
  --tableRowYellow: #fff9dc;
  --tableRowRed: #ffe9ea;
  --tableRowGray: #efefef;
  --blue: #3f7abf;
  --white: #fff;
  --green: #58a621;
  --grey: #747474;
  --light-grey: #e8e8e8;
  --red: #f26161;
  --contentColor: #e6ede0;
  --sidebarBg: #fff;
}
// DARK THEME
html[theme="dark"] {
  --bg: #544b6b;
  --primary: #ccb279;
  --text: #fff;
  --headerText: #fff;
  --headerColor: #786b99;
  --paginationColor: #6c757e;
  --paginationActiveColor: #707070;
  --modalBackground: #fff;
  --modalText: #818e9b;
  --modalSubHeader: #818e9b;
  --tableHeader: #fafafa;
  --tableHeaderColor: #818e9b;
  --iconColor: #818e9b;
  --headerIconColor: #fff;
  --checkboxColor: #3f7abf;
  --drawItemColor: #f2f2f2;
  --filtersHeader: #000;
  --tableRowGreen: #254200;
  --tableRowYellow: #453f07;
  --tableRowRed: #370e0e;
  --tableRowGray: #555;
  --blue: #3f7abf;
  --white: #fff;
  --green: #58a621;
  --grey: #747474;
  --light-grey: #e8e8e8;
  --red: #f26161;
  --contentColor: #000;
  --sidebarBg: #867aa3;
}

//SIZE
$size-xl: 1200px;
$size-lg: 1024px;
$size-md: 768px;
$size-sm: 576px;
$padding-xl: 108px;
$padding-lg: 60px;
$padding-md: 30px;
$padding-sm: 15px;

// COLORS
$red: var(--red);
$blue: var(--blue);
$primary: #FDDA59;
$primary-hover: #FCC741;
$btn-disabled-bg: #F0F0F0;
$gray-light: #F3F8FC;
$text-dark: #002639;
$text-gray: #484848;
$text-gray-second: #9499A2;
$text-gray-third: #B3B2B2;
$border-color: #C6CDD3;
$color-white: #fff;
$color-white-second: #F0F0F0;
$overlay-bg: #434242;
$bg-dark-blue: #1B2945;
$color-calendar: #FB8264;
$color-link: #4563CB;

$color-online: #00BCD3;
$color-vebinar: #48CFAF;
$color-training: #3D84C8;
$color-meeting: #FB8264;

$color-feedback-bg: #8b95a4;
$color-select: #C9CCD4;

$animation-menu: .5s;
