.header{
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px;
  box-sizing: border-box;

  & .header-menu-icon{
    cursor: pointer;
    margin-right: 36px;

    &:hover{
      opacity: 0.5;
    }
  }


  & .header--burger{
    display: flex;
    align-items: center;
  }

  & .header--logo, .header--logo-1024{
    height: 36px;
    width: 235px;
    background: url("../../static/img/logo.png") no-repeat;
    background-size: 235px 36px;
    display: none;
    cursor: pointer;
  }

  .header--logo-1024{
    display: block;
  }

  & .header--phone{
    display: flex;
    align-items: center;
    justify-content: right;
  }

  & .phone-number{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;

    &--name{
      color: $text-gray-second;
      font-size: 13px;
      line-height: 14px;
      text-align: right;
      text-transform: lowercase;
    }

    &--ico{
      height: 24px;
      width: 24px;
      background: url("../../static/ico/ico_phone.png") no-repeat;
      margin: 0 8px;
    }

    &--number{
      font-size: 24px;
      font-weight: bold;
      color: $text-dark;
    }
  }
}


@media screen and (max-width: $size-lg) {
  .header .phone-number, .header .header--logo{
    display: none;
  }


  & .header .header--logo-1024{
    display: none;
  }

  & .header .header--logo{
    display: block;
  }

}

@media screen and (max-width: $size-sm) {
  .header {
    justify-content: left;
    padding: 0 $padding-sm;


    & .header--phone{
      display: none;
    }
  }
}
