.info-training-info {

  background: $color-white;
  box-shadow: 0 11px 21px rgba(150, 155, 163, 0.31);
  width: 85%;

  & .img {
    &  img {
      width: 100%;
    }

    & a{
      display: block;
      transition: .6s;

      &:hover{
        opacity: .8;
      }
    }

    & .wrapper_img {
      position: relative;

      & .img-overlay{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        background: url("../../../static/ico/ico_overlay_youtube.png") no-repeat center;
      }
    }
  }

  & .wrapper-info {
    padding: 30px 20px 10px 30px;

    & > div {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      & .ico{
        min-width: 58px;
      }

      & > div > div:first-child {
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: $text-gray-second;
      }

      & > div > div:last-child {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.47px;
        color: $text-gray;
      }
    }
  }

}

@media screen and (max-width: $size-xl) {

}

@media screen and (max-width: $size-lg) {
  .info-training-info{
    width: 90%;
  }
}

@media screen and (max-width: $size-md) {
  .info-training-info{
    width: 100%;
  }
}

@media screen and (max-width: $size-sm) {

}
