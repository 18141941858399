body {
  .ant-btn {
    cursor: pointer;
    color: $text-gray;
    text-transform: uppercase;
    border-radius: 0;
    height: 60px;
    line-height: 40px;
    padding: 0 12px;
    letter-spacing: 2px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: normal;
    padding-left: 28px;
    padding-right: 28px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > svg{
      margin-right: 10px;
    }


    &.arrow{
      & svg{
        margin-right: 0;
        margin-left: 43px;
      }

      & .ant-btn-loading-icon svg{
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

 .ant-btn-primary, .ant-btn-primary:focus{
   background: $primary;
   border-color: $primary;
   color: $text-gray;

   &:hover{
     background: $primary-hover;
     border-color: $primary-hover;
     color: $text-gray;
   }
 }
}
