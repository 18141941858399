.info-list-training{
  display: flex;
  justify-content: space-between;

  margin-top: 75px;
  margin-bottom: 40px;




  &--text{
    color: $text-dark;
    margin-right: 60px;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
  }

  &--icons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%;

    & > div{
      width: 263px;
      margin-right: 31px;
      position: relative;

    }

    & .icon{
      width: 100px;
      height: 100px;
      border: 2px dashed $text-gray-second;
      border-radius: 6px;
      background-repeat: no-repeat;
      background-position: center;
    }

    & .online{
      background-image:  url("../../../static/ico/ico_online.png");
    }
    & .training{
      background-image:  url("../../../static/ico/ico_training2.png");
    }
    & .corporat{
      background-image:  url("../../../static/ico/ico_corporat.png");
    }


    & .info{
      position: absolute;
      top: 20px;
      left: 90px;
      background: #fff;

      & div:first-child{
        font-size: 32px;
        line-height: 38px;
        font-weight: bold;
        color: $text-dark;
      }

      & div:last-child{
        font-size: 16px;
        line-height: 22px;
        color: $text-gray-second;
      }
    }

    & .two-line{
      top: 10px;
    }
  }
}

@media screen and (max-width: 1220px) {
  .info-list-training{
    &--text{
      display: none;
    }
  }
}
@media screen and (max-width: $size-lg) {
  .info-list-training{

  }
}
@media screen and (max-width: $size-md) {
  .info-list-training{
    &--icons {
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;

      & > div{
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: $size-sm) {
  .info-list-training{
  }
}

