.wrapper-div {

  padding-left: $padding-xl;
  padding-right: $padding-xl;
}

@media screen and (max-width: $size-xl) {
  .wrapper-div {
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }
}

@media screen and (max-width: $size-lg) {
  .wrapper-div {
    padding-left: $padding-lg;
    padding-right: $padding-lg;
  }
}

@media screen and (max-width: $size-md) {
  .wrapper-div {
    padding-left: $padding-md;
    padding-right: $padding-md;
  }
}

@media screen and (max-width: $size-sm) {
  .wrapper-div {
    padding-left: $padding-sm;
    padding-right: $padding-sm;
  }
}
