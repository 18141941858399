.men-main{
  display: flex;
  justify-content: space-between;

  margin-left: $padding-xl;
  margin-right: $padding-xl;
  margin-top: 75px;
  margin-bottom: -7px;

  &--info{
    width: 100%;
  }

  &--men{
    min-width: 270px;
    height: 309px;
    background: url("../../../static/img/bg_man.png") no-repeat;
  }

  &--text{
    font-size: 18px;
    line-height: 31px;
    color: $text-gray;
    margin-right: 80px;
    opacity: 0.8;
  }

  &--icons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    & > div{
      width: 263px;
      margin-right: 31px;
      position: relative;

    }

    & .icon{
      width: 100px;
      height: 100px;
      border: 2px dashed $text-gray-second;
      border-radius: 6px;
      background-repeat: no-repeat;
      background-position: center;
    }

    & .online{
      background-image:  url("../../../static/ico/ico_online.png");
    }
    & .training{
      background-image:  url("../../../static/ico/ico_training2.png");
    }
    & .corporat{
      background-image:  url("../../../static/ico/ico_corporat.png");
    }


    & .info{
      position: absolute;
      top: 20px;
      left: 90px;
      background: #fff;

      & div:first-child{
        font-size: 32px;
        line-height: 38px;
        font-weight: bold;
        color: $text-dark;
      }

      & div:last-child{
        font-size: 16px;
        line-height: 22px;
        color: $text-gray-second;
      }
    }

    & .two-line{
      top: 10px;
    }
  }
}

@media screen and (max-width: $size-lg) {
  .men-main{
    margin-right: $padding-lg;
    margin-left: $padding-lg;
    align-items: center;

    &--icons{
      display: none;
    }

    &--text{
      margin-left: 48px;
    }
  }
}
@media screen and (max-width: $size-md) {
  .men-main{
    margin-right: $padding-md;
    margin-left: $padding-md;

    &--text{
      margin-left: 0;
      margin-right: 20px;
    }
  }
}
@media screen and (max-width: $size-sm) {
  .men-main{
    display: none;
  }
}

@media screen and (max-width: 1330px) {
  .men-main{
    .ico-third{
      display: none;
    }
  }
}
