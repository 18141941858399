.subscribe-main{
  height: 210px;
  margin-left: $padding-xl;
  margin-right: $padding-xl;
  background: url("../../../static/img/bg_mail.png") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 60px;
  padding-left: 50px;
  padding-right: 50px;

  & > div:first-child{
    font-size: 28px;
    line-height: 38px;
    font-weight: bold;
    color: $color-white;
    padding-right: 90px;
  }

  & > div:last-child{
    width: 490px;
    min-width: 300px;
  }

  & .spam-component{
    & input{
      height: 60px;
    }

    & .ant-btn{
      height: 60px;
    }

    & button:disabled{
      background: $primary;
      border-color: $primary;
      color: $text-gray;
    }
  }
}

@media screen and (max-width: $size-lg) {
  .subscribe-main{
    margin-right: $padding-lg;
    margin-left: $padding-lg;
    padding-left: 40px;
    padding-right: 40px;

    & > div:first-child{
      padding-right: 40px;
    }
  }
}
@media screen and (max-width: $size-md) {
  .subscribe-main{
    margin-right: 0;
    margin-left: 0;
    padding-left: 30px;
    padding-right: 30px;

    & > div:first-child{
      padding-right: 20px;
    }
  }
}
@media screen and (max-width: $size-sm) {
  .subscribe-main{
    background: $bg-dark-blue;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    height: 370px;
    padding-top: 45px;

    & > div:first-child{
      padding-right: 0;
      text-align: center;
      font-size: 27px;
    }

    & > div:last-child{
      width: 100%;
      min-width: 100%;
    }

    & .spam-component{
      flex-direction: column;
      margin-top: 45px;

      & input{
        height: 60px;
      }

      & .ant-btn{
        height: 60px;
        margin-top: 45px;
      }
    }
  }
}
