.header-info {

  height: 294px;
  background: url("../../../static/img/bg_training_info.jpg") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;


  & .title{
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
    letter-spacing: -1px;
    color: $color-white;
    margin-top: 15px;
  }

  & .section{
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 1.08333px;
    text-transform: uppercase;
    color: $text-gray-third;
    margin-top: 40px;
  }

}

@media screen and (max-width: $size-xl) {

}

@media screen and (max-width: $size-lg) {

}

@media screen and (max-width: $size-md) {

}

@media screen and (max-width: $size-sm) {
  .header-info{
    background: $bg-dark-blue;

    & .section{
      margin-top: 10px;
    }
  }
}
