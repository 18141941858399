.content-info {
  display: flex;
  justify-content: space-between;

  & .about{
    width: 55%;

    font-size: 15px;
    line-height: 24px;
    color: $text-gray;
    margin-right: 20px;
    text-align: justify;
  }

  & .info{
    width: 45%;
    display: flex;
    justify-content: center;
    margin-top: -20%;
    height: 100%;
  }

  &--form{
    margin: 50px 0 60px 0;
  }

}

@media screen and (max-width: $size-xl) {

}

@media screen and (max-width: $size-lg) {
  .content-info {

    & .about{
      width: 50%;
    }

    & .info{
      width: 50%;
    }

  }
}

@media screen and (max-width: $size-md) {
  .content-info{
    flex-direction: column-reverse;

    & .about, .info {
      width: 100%;
    }

    & .about{
      margin-top: 50px;
    }

    & .info{
      margin-top: -100px;
    }
  }
}

@media screen and (max-width: $size-sm) {
  .content-info{

  }
}
