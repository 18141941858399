.question-main{
  margin: 100px $padding-xl;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div{
    margin-right: 110px;

    &:last-child{
      margin-right: 0;
    }
  }

  &--text{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: $text-gray;
    line-height: 28px;

    & > div{
      margin-right: 110px;

      &:last-child{
        margin-right: 0;
      }
    }
  }

  &--button{
    font-size: 26px;
    line-height: 40px;
    color: $text-dark;
    letter-spacing: -1.25px;
    font-weight: bold;

    & .ant-btn{
      margin-top: 36px;
    }
  }
}

@media screen and (max-width: $size-lg) {
  .question-main{
    margin-right: $padding-lg;
    margin-left: $padding-lg;

    & > div{
      margin-right: 30px;
    }

    &--text {
      & > div{
        margin-right: 30px;
      }
    }
  }
}
@media screen and (max-width: $size-md) {
  .question-main{
    margin-right: $padding-md;
    margin-left: $padding-md;

    &--text {
      flex-direction: column;

      & > div{
        margin-right: 0;
        margin-bottom: 36px;
      }
    }
  }
}
@media screen and (max-width: $size-sm) {
  .question-main{
    margin-right: $padding-sm;
    margin-left: $padding-sm;
    flex-direction: column;


    &--button{
      margin-bottom: 66px;
      margin-right: 0 !important;
      text-align: center;

      & .wrapper-btn{
        display: flex;
        justify-content: center;
      }
    }
  }
}

