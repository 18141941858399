.training-info {

  &--content{
    background: $gray-light;
    padding: 60px 0;
  }

  & .new-main.random{
    background: none;
    margin-bottom: 80px;
    h2{
      font-size: 36px;
      line-height: 64px;
      text-align: center;
      letter-spacing: -1.5px;
      color: $text-dark;
      margin-bottom: 60px;
    }
  }
}

@media screen and (max-width: $size-xl) {

}

@media screen and (max-width: $size-lg) {

}

@media screen and (max-width: $size-md) {

}

@media screen and (max-width: $size-sm) {

}
