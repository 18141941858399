.trainings-page, .list-training {

  .wrapper-header-train {
    display: flex;
    justify-content: left;
  }

  .header-train {
    height: 458px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 80px;
    padding-bottom: 80px;

    & .title {
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      letter-spacing: -1px;
      color: $color-white;
      margin-right: 20px;
    }

    & .text {
      width: 100%;
      font-size: 18px;
      line-height: 32px;
      color: $color-white;
      font-weight: normal;
      margin-top: 20px;
    }

    & .youtube_link {
      background: $primary;
      color: $text-dark;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      padding: 24px;
      box-sizing: border-box;
      max-width: 350px;
      min-width: 300px;

      & .title_link{
        margin-top: 30px;
        margin-bottom: 60px;
      }
    }

  }

  & .img-distance {
    background-image: url("../../../static/img/bg_distance.jpg");
  }

  & .img-training {
    background-image: url("../../../static/img/bg_trainigs.jpg");
  }

  & .img-corporat {
    background-image: url("../../../static/img/bg_corporat.jpg");
  }


  & .content {
    padding: 120px 0;
  }
}


@media screen and (max-width: $size-lg) {
  .trainings-page {


    .header-train {

      & .title {
        min-width: 40%;
      }


    }
  }
}

@media screen and (max-width: $size-md) {

}

@media screen and (max-width: $size-sm) {
  .trainings-page {

    .wrapper-header-train {
      flex-direction: column;
    }

    & .youtube_link{
      margin-top: 30px;
      width: 100%;
      min-width: 100% !important;
    }

    .header-train {
      background: $bg-dark-blue;
      height: 100%;

      & .title {
        width: 100%;
      }


    }
  }
}

