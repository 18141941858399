* {
  font-family: "Open Sans";
}

body {


  & .clear-both{
    clear: both !important;
  }

  .bg-online{
    background-color: $color-online !important;
    color: $color-white !important;
  }
  .bg-vebinar{
    background-color: $color-vebinar !important;
    color: $color-white !important;
  }
  .bg-training{
    background-color: $color-training !important;
    color: $color-white !important;
  }
  .bg-meeting{
    background-color: $color-meeting !important;
    color: $color-white !important;
  }

  & h1{
    font-weight: bold;
    font-size: 46px;
    line-height: 64px;
    text-align: center;
    letter-spacing: -1.5px;
    color: $text-dark;
    margin-top: 100px;
    margin-bottom: 40px;
  }

  & .div-text{
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
    color: $text-gray;
    margin-bottom: 60px;
  }

  & .spin{
    display: flex;
    justify-content: center;
  }

  & .main-spin{
    position: absolute;
    top: 45%;
    width: 100%;
  }

  & .empty-data{
    display: flex;
    justify-content: center;
  }
}
