.subscribe-list-training{
  height: 210px;
  background: url("../../../static/img/bg_mail.png") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 50px;
  padding-right: 50px;

  & > div:first-child{
    font-size: 28px;
    line-height: 38px;
    font-weight: bold;
    color: $color-white;
    padding-right: 90px;
    min-width: 300px;
    width: 65%;

  }

  & .title{
    margin-bottom: 25px;
  }

  & > div:last-child{
    width: 35%;
    display: flex;
    justify-content: flex-end;
  }
  & .man{
    width: 270px;
    height: 309px;
    background: url("../../../static/img/bg_man.png") no-repeat;
    margin-top: -87px;
    margin-right: -89px;
  }

  & .spam-component{
    & input{
      height: 60px;
    }

    & .ant-btn{
      height: 60px;
    }
  }
}

@media screen and (max-width: $size-lg) {
  .subscribe-list-training{
    padding-left: 40px;
    padding-right: 40px;

    & > div:first-child{
      padding-right: 40px;
    }
  }
}
@media screen and (max-width: $size-md) {
  .subscribe-list-training{
    padding-left: 30px;
    padding-right: 30px;

    & > div:first-child{
      padding-right: 0;
      width: 100%;
    }

    & > div:last-child{
      display: none;
    }

  }
}
@media screen and (max-width: $size-sm) {
  .subscribe-list-training{
    background: $bg-dark-blue;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    height: 370px;
    padding-top: 45px;

    & > div:first-child{
      padding-right: 0;
      text-align: center;
      font-size: 27px;
    }

    & > div:last-child{
      width: 100%;
      min-width: 100%;
    }

    & .spam-component{
      flex-direction: column;
      margin-top: 45px;

      & input{
        height: 60px;
      }

      & .ant-btn{
        height: 60px;
        margin-top: 45px;
      }
    }
  }
}
