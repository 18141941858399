.list-training-component{
  padding: 40px 0;

  & .select-section{
    margin-bottom: 30px;
  }

  & .list-training-select{
    background: $color-select;
    color: $color-white;
    width: 230px !important;
    padding: 8px 0;

    & .ant-select-selection-placeholder{
      opacity: 1;
    }

    & .ant-select-clear svg{
      background: $color-select;
    }
    & .ant-select-arrow{
      color: $color-white;
    }
  }
}

