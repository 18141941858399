.enrollment-training-info {
  width: 80%;

  & h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -1.5px;
    color: $text-dark;
    margin-bottom: 30px;
  }

  & > div > div {
    margin-bottom: 16px;
  }

  & .tel {
    display: flex;

    & > label:first-child {
      width: 35%;
      margin-right: 25px;
      margin-bottom: 16px;
    }
  }

  & .wrapper-btn {
    margin-top: 36px;
  }

}

@media screen and (max-width: $size-xl) {

}

@media screen and (max-width: $size-lg) {
  .enrollment-training-info {
    width: 100%;
  }
}

@media screen and (max-width: $size-md) {

}

@media screen and (max-width: $size-sm) {
  .enrollment-training-info {
    & .tel {
      display: flex;
      flex-direction: column;

      & > div:first-child {
        width: 100%;
        margin-right: 0;
      }
    }

  }
}
